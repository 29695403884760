<template>
	<div class="grid mb-4">
		<div class="title">{{ isNew ? 'Создание тарифа' : 'Тариф' }}</div>
		<form @submit.prevent class="tariffs">
			<BaseGroup title="Основные данные" class="">
				<BaseInput v-model="formData.dateStart" type="date" placeholder="Действует от"/>
				<BaseInput v-model="formData.dateEnd" type="date" placeholder="Действует до"/>
				<BaseSelect
					placeholder="Выберите вид рыбы"
					:options="fishes"
					v-model:option="formData.fish.id"
					@update:option="selectFish"
				/>
				<BaseSelect
					placeholder="Выберите сорт рыбы"
					:options="fishesSort"
					v-model:option="formData.fishSort.id"
					@update:option="selectFishSort"
				/>
				<BaseInput v-model="formData.price" placeholder="Цена" :decimalNumber="true"/>
			</BaseGroup>

			<div class="tariffs--controls">
				<BaseButton @click="addTariff" :disabled="!isFormComplete"> Сохранить</BaseButton>
				<BaseButton v-if='!isNew' view="danger" @click="deleteTariff"> Удалить</BaseButton>
			</div>

		</form>
	</div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import BaseGroup from '../components/ui/BaseGroup'
import BaseSelect from '../components/ui/BaseSelect'
import BaseInput from '../components/ui/BaseInput'
import BaseButton from '../components/ui/BaseButton'
import { useApi } from '../modules/api'
import { useRoute, useRouter } from 'vue-router'
import isObjectEmpty from '@/utils/isObjectEmpty'
import toTimestamp from '@/utils/toTimestamp'
import { getInputDate } from '@/utils/getInputDate'
import BOOKS from '../modules/constants/books'
import { useAlert } from '@/modules/composition/useAlert'

export default {
	name: 'TariffsNew',
	components: {
		BaseGroup,
		BaseSelect,
		BaseInput,
		BaseButton
	},
	setup () {
		const { errorAlert } = useAlert()
		const route = useRoute()
		const router = useRouter()
		const formData = ref({
			dateEnd: null,
			dateStart: null,
			fish: {
				c1Id: null,
				id: null,
				name: null
			},
			fishSort: {
				id: null,
				name: null
			},
			price: null
		})
		const fishes = ref({})
		const fishesC1Id = ref({})
		const fishesSort = ref({})
		const isNew = route.name === 'TariffsNew'
		const { tariffs } = BOOKS

		const selectFish = (id) => {
			const name = fishes.value[id]
			const c1Id = fishesC1Id.value[id]
			formData.value.fish = {
				id, c1Id, name
			}
		}

		const selectFishSort = (id) => {
			const { name } = fishesSort.value[id]
			formData.value.fishSort = {
				id, name
			}
		}

		const addTariff = async () => {
			const { post, error } = useApi(tariffs.endpoint)

			await post({
				...formData.value,
				dateEnd: toTimestamp(formData.value.dateEnd),
				dateStart: toTimestamp(formData.value.dateStart)
			})

			if (error.value) {
				errorAlert(`Ошибка при сохранении данных! ${error.value?.message}`)
			} else {
				await router.push('/tariffs')
			}
		}

		const deleteTariff = async () => {
			const { del, error } = useApi(`${tariffs.endpoint}/${route.params.id}`)
			await del()

			if (error.value) {
				errorAlert(`Ошибка при удалении данных! ${error.value?.message}`)
			} else {
				await router.push('/tariffs')
			}
		}

		const isFormComplete = computed(() => {
			return formData.value.dateStart &&
				formData.value.dateEnd &&
				new Date(formData.value.dateEnd) >= new Date(formData.value.dateStart) &&
				!isObjectEmpty(formData.value.fish) &&
				!isObjectEmpty(formData.value.fishSort) &&
				formData.value.price
		})

		onMounted(async () => {
			const fishApi = useApi('/dictionary/fish')
			const fishSortApi = useApi('/dictionary/fishSort')

			await fishApi.get()
			await fishSortApi.get()
			fishApi.data.value.forEach(el => {
				fishes.value[el.id] = el.name
				fishesC1Id.value[el.id] = el.c1Id
			})
			fishSortApi.data.value.forEach(el => {
				fishesSort.value[el.id] = el.name
			})

			if (!isNew) {
				const { get, data } = useApi(`${tariffs.endpoint}/${route.params.id}`)

				await get()

				data.value.dateStart = getInputDate(new Date(data.value.dateStart * 1000))
				data.value.dateEnd = getInputDate(new Date(data.value.dateEnd * 1000))
				formData.value = data.value
			}
		})

		return {
			isNew,
			formData,
			isFormComplete,
			fishes,
			fishesSort,
			selectFish,
			selectFishSort,
			addTariff,
			deleteTariff
		}
	}
}
</script>

<style lang="scss" scoped>
.tariffs {
	&--controls {
		margin-top: 20px;

		.button {
			width: 120px;

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}
</style>
